define(['app',
  'module','siteObj','enhancedEcom'], function(app, module, siteObj ,enhancedEcom) {

  var productQuickbuySimple = function() {
    var component = {};

    var _config = {
      dataAttributes: {
        productIdSelector: 'data-product-id',
        siteIdSelector: 'data-site-id',
        fromWishlist: 'data-from-wishlist',
        defaultLocaleSelector: 'data-default-locale',
        subsiteCodeSelector: 'data-subsite-code',
        headerBasketSelector: 'header-basket',
        basketItemsSelector: 'basket-items',
        basketQuantityClass: 'header-menu-basket-counter',
        recommendationContext: 'data-context',
        recommendationParent: '[data-component=productRecommendations]',
        hasEnhancedEcomAtt:  'data-has-enhanced-ecom',
        fulfilmentType: 'data-fulfilment-type',
        hasFulfilmentMethod: 'data-has-fulfilment-method',
      },
      subscribeChannels: {
        addedToBasket: 'productAddedToBasket/loadSuccess',
      },
      soldOutClass: 'productQuickbuySimple-soldOut'
    };

    var _init = function(element) {
      component.element = element;
      component.parentElement = component.element.parentElement.parentElement;

      if (component.element) {
        component.productId = component.element.getAttribute(component.config.dataAttributes.productIdSelector);
        component.siteId = component.element.getAttribute(component.config.dataAttributes.siteIdSelector);
        component.defaultLocale = component.element.getAttribute(component.config.dataAttributes.defaultLocaleSelector);
        component.subsiteCode = component.element.getAttribute(component.config.dataAttributes.subsiteCodeSelector);
        component.fromWishlist = component.element.getAttribute(component.config.dataAttributes.fromWishlist);

        component.element.addEventListener('click', component.triggerAddToBasket);

        if (component.parentElement) {
          component.fulfilmentType = component.parentElement.getAttribute(component.config.dataAttributes.fulfilmentType);

          if (component.linkedProduct === 'true') {
            if (productId) {
              component.productId = productId;
            }
          }
        }
      }

      component.basketHeaderClass = module.config().basketHeaderClass || component.config.dataAttributes.headerBasketSelector;
      component.basketHeaderID = module.config().basketHeaderID || false;
      component.basketItemsContainerClass = module.config().basketItemsContainerClass || component.config.dataAttributes.basketItemsSelector;

      component.context = component.getRecommendationContext();

      return component;
    };

    var _checkSubscribeMessages = function(subscription, callback) {
      if (subscription.messages.length > 0) {
        var lastItem = subscription.messages.slice(-1)[0];
        callback(lastItem);
      }
    };

    var _triggerAddToBasket = function(event) {

      event.preventDefault();

      app.publish('addedToBasketModal/hideError', '', false);
      app.publish('productQuickbuy/hideError', '', false);
      app.publish('productQuickbuy/startLoading', '', false);
      app.publish('tracking/record', 'Product | AddToBasket', 'clicked', component.productId);

      let queryObj = {
        productId: component.productId,
        siteId: component.siteId,
        siteDefaultLocale: component.defaultLocale,
        siteSubsiteCode: component.subsiteCode,
        variationLength: 0,
        quantity: 1,
        fromWishlist: component.fromWishlist
      };

      if (component.context) {
        queryObj['rctxt'] = component.context;
      }

      if (component.element.hasAttribute(component.config.dataAttributes.hasFulfilmentMethod)) {
        queryObj.fulfilmentType = component.element.getAttribute(component.config.dataAttributes.fulfilmentType);;
      }

      component.postAddToBasket(queryObj);
    };

    var _postAddToBasket = function(queryObj) {
      if (queryObj && Object.keys(queryObj).length > 0) {
        if (component.element.baseURI.includes('worthByPrice') || component.element.baseURI.includes('noWorth')) {
          queryObj['worthValue'] = 'worthByPrice';
        }
        app.ajax.get({
          url: '/basketinterface.json?' + querify(queryObj),
          success: component.addToBasketSuccess,
          error: component.addToBasketError
        });
      }
    };

    var _addToBasketError = function() {
      app.publish('productQuickbuy/stopLoading', '', false);
      app.publish('productQuickbuy/showError', '', false);
      app.publish('addedToBasketModal/showError', '', false);
    };

    var _addToBasketSuccess = function(data) {
      var jsonData = JSON.parse(data);
      var modalData = component.getLastAddedItemData(jsonData.simpleBasket);
      app.publish('productQuickbuy/hideModal', '', false);
      app.publish('addedToBasketModal/productAdded', modalData, false);
      app.publish('globalBasketItemsCount/updateGlobalBasketItemsCount', modalData.basketTotalItems);
      app.publish('BasketItemAdded', jsonData);
      app.publish('tracking/record', 'Product | AddToBasket', 'success', component.productId, jsonData.simpleBasket.requestEventId);

      if (jsonData.simpleBasket.lastAddedItem) {
        const lastAddedItemSku = jsonData.simpleBasket.lastAddedItem.productId;
        if (window.dataLayer.length) {
          window.dataLayer[0].lastAddedItemSku = lastAddedItemSku.toString();
        };
      }
    };

    var _getLastAddedItemData = function(simpleBasket) {
      var lastAddedItem = simpleBasket.lastAddedItem;

      var data = {
        worthValue: lastAddedItem.worthValue,
        productId: lastAddedItem.productId,
        productTitle: lastAddedItem.productTitle,
        productUrl: lastAddedItem.productURL,
        productQuantity: simpleBasket.lastAddedQuantity,
        productQuantityRequested: simpleBasket.lastAddedQuantityRequested,
        productPrice: lastAddedItem.price,
        basketTotalPrice: simpleBasket.totalprice,
        recommendations: simpleBasket.recommendations,
        basketTotalItems: simpleBasket.totalUnits,
        message: simpleBasket.message,
        lastAddedMaxPerOrder: lastAddedItem.maxPerOrder,
        loyaltySchemePoints: simpleBasket.loyaltySchemePoints
      };

      let sku = "";
      if(lastAddedItem.externalSku) {
        sku = lastAddedItem.externalSku;
      } else {
        sku = lastAddedItem.productId;
      }

      var imageURL = '';
      if (lastAddedItem.productImages && lastAddedItem.productImages.largeproduct) {
        imageURL = lastAddedItem.productImages.largeproduct;
      }
      data.productImageUrl = imageURL;

      if(component.parentElement.hasAttribute(component.config.dataAttributes.hasEnhancedEcomAtt)) {
        enhancedEcom.addToBasket(siteObj.currencyType, lastAddedItem.productTitle, sku, lastAddedItem.price, simpleBasket.lastAddedQuantity, lastAddedItem.brand, component.fulfilmentType, lastAddedItem.inStockWarehouse, lastAddedItem.collectionType, lastAddedItem.category, lastAddedItem.deliverable);
      }

      return data;
    };

    var _getRecommendationContext = function() {
      // polyfill for IE8+
      if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector ||
          Element.prototype.webkitMatchesSelector;
      }

      if (!Element.prototype.closest) {
        Element.prototype.closest = function (s) {
          var el = this;
          if (!document.documentElement.contains(el)) return null;
          do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
          } while (el !== null && el.nodeType === 1);
          return null;
        };
      }

      const recommendationParent = component.element.closest(component.config.dataAttributes.recommendationParent);
      return (recommendationParent) ? recommendationParent.getAttribute(component.config.dataAttributes.recommendationContext) : '';
    };

    component.init = _init;
    component.config = _config;
    component.checkSubscribeMessages = _checkSubscribeMessages;
    component.triggerAddToBasket = _triggerAddToBasket;
    component.postAddToBasket = _postAddToBasket;
    component.addToBasketSuccess = _addToBasketSuccess;
    component.addToBasketError = _addToBasketError;
    component.getLastAddedItemData = _getLastAddedItemData;
    component.getRecommendationContext = _getRecommendationContext;

    return component;
  };

  const querify = obj => Object.entries(obj).map(([key, value]) => `${key}=${value}`).join('&');

  return productQuickbuySimple;
});
